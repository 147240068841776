import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-minimum-app-version"
    }}>{`Configure Minimum App Version`}</h1>
    <hr></hr>
    <p>{`To configure the minimum app version that you want patrons accessing and using your app on, follow these step-by-step instructions. `}<em parentName="p">{`If a patron attempts to access the app on a version that is older than what is detailed, the app will prompt them to update to the latest version by linking them to the appropriate App Store:`}</em></p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Select the app you wish to edit. `}</li>
      <li parentName="ol">{`Scroll to the bottom of the page to the Min. Supported Version section. `}</li>
    </ol>
    <p><img alt="CMS Min Supported Version" src={require("./images/cms_min_version.png")} />{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Detail the minimum supported version your app will run on in the 'Android' and 'iOS' fields. To learn about the different versions of the app, please refer to the `}<a parentName="li" {...{
          "href": "https://appdocs.sol.us/releases"
        }}>{`Releases`}</a>{` page for more information. `}</li>
      <li parentName="ol">{`Provide a message title in the 'Upgrade Message' field. This will appear at the top of the patron's device when they open an older version of the app as well as be the button text used to link the patron to the correct App Store. This field can hold 100 characters. `}</li>
      <li parentName="ol">{`Provide a subtitle for your upgrade message in the 'Upgrade Subtitle' field. This will display below the 'Upgrade Message'. This field can hold 100 characters. `}</li>
      <li parentName="ol">{`Provide a description for your upgrade message. This field can hold 600 characters. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      